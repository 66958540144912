import { AgentKey, AgentType } from '../app/core/types/agents.interface';

export const environment = {
  BASE_API_URL: 'https://dev.api.oloucoportech.com/api/',
  AGENTS_TOKEN: '6654ba8972d8fac848866bf5',
  store: {
    LOJA_END_POINT: 'https://dev.api.oloucoportech.com/api/stores',
    LOJA_REGISTER: 'https://dev.api.oloucoportech.com/api/stores',
    TOTAL_STORES: 'https://dev.api.oloucoportech.com/api/stores/count',
  },
  users: {
    auth: {
      USERS_URL_LOGIN: 'https://dev.api.oloucoportech.com/api/users/login',
      password: {
        RESET_BY_TOKEN:
          'https://dev.api.oloucoportech.com/api/users/password/reset-by-token',
        SEND_RESET_TOKEN:
          'https://dev.api.oloucoportech.com/api/users/password/send-reset-token',
      },
    },
    USER_URL_PROFILE_IMAGE:
      'https://dev.api.oloucoportech.com/api/users/me/image',
    USERS_URL: 'https://dev.api.oloucoportech.com/api/users',
    USERS_URL_ME: 'https://dev.api.oloucoportech.com/api/users/me',
  },
  message: {
    BASE_URL: 'https://dev.api.oloucoportech.com/api/messages',
  },
  socket: {
    agents: {
      WSS_URL: 'agents.oloucoportech.com',
    },
    endpoints: {
      BASE_URL: 'https://dev.api.oloucoportech.com/api/agent',
      CREATE_AGENT: 'https://dev.api.oloucoportech.com/api/agent/create',
      ADD_AGENT: 'https://dev.api.oloucoportech.com/api/agent/integration/add',

      UPDATE_AGENT: 'https://dev.api.oloucoportech.com/api/agent/update',
      DELETE_AGENT: 'https://dev.api.oloucoportech.com/api/agent/update',
      connection: {
        CONNECT_AGENT: 'https://dev.api.oloucoportech.com/api/agent/connect',

        DISCONNECT_AGENT:
          'https://dev.api.oloucoportech.com/api/agent/disconnect',

        GET_STATUS_AGENT: (agent: AgentType) =>
          `https://dev.api.oloucoportech.com/api/agent/status?type=${agent}`,
      },
      chats: {
        GET_CHAT: 'https://dev.api.oloucoportech.com/api/agent/chats',
        ADD_OR_REMOVE_CHAT:
          'https://dev.api.oloucoportech.com/api/agent/chats/mark',

        SEND_MESSAGE: 'https://dev.api.oloucoportech.com/api/agent/send/text',

        SHOOT_MESSAGE: 'https://dev.api.oloucoportech.com/api/agent/shoot/text',

        SEND_MEDIA_OR_MESSAGE_MEDIA:
          'https://dev.api.oloucoportech.com/api/agent/send/media',

        SEND_MESSAGE_MEDIA_FOR_CHATS:
          'https://dev.api.oloucoportech.com/api/agent/shoot/media',
      },
    },
    envs: {
      PASS_ROOT: '',
      PASS_USER: '',
    },
  },
  core: {
    auth: {
      password:
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^*-+&]).{8,}$',
      EMAIL_URL: 'https://dev.api.oloucoportech.com/api/users/verify',
    },
    url: 'http://localhost:4200',
    images:
      'https://dev.api.oloucoportech.com/api/messages/get-image-from-link',
  },
  permissions: {
    BASE_URL: 'https://dev.api.oloucoportech.com/api/permissions',
    SET_PERMISSION: '',
    groups: {
      BASE_URL: 'https://dev.api.oloucoportech.com/api/groups',
    },
  },
  shoot: {
    BASE_URL: 'https://dev.api.oloucoportech.com/api/agent/shoot',
    TRIGGERING_STATUS: (key: AgentKey) =>
      `https://dev.api.oloucoportech.com/api/agent/shoot/history?key=${key}`,
  },
  db: {
    name: 'dev_chats',
  },
};
