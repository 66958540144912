@if (testIfSrcIsUrl(src)) {
<img class="w-14 h-14 rounded-full object-cover" [src]="src" alt="user photo" />
} @else {
<div
  class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
>
  <span class="font-medium text-gray-600 dark:text-gray-300">{{
    getInitialsForUsername()
  }}</span>
</div>
}
