import { Component, Input } from '@angular/core';
import { UserService } from '../../../../../../core/services/user/user.service';

@Component({
  selector: 'app-photo',
  standalone: true,
  imports: [],
  templateUrl: './photo.component.html',
})
export class PhotoComponent {
  constructor(private user: UserService) {}
  @Input({ required: true }) public src!: string;
  @Input({ required: true }) public name!: string;

  protected testIfSrcIsUrl(src: string): boolean {
    const validator = new RegExp(
      /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/,
    );

    return validator.test(src);
  }

  protected getInitialsForUsername() {
    const name = this.name.split(' ');

    if (name.length === 1) {
      return name[0][0];
    }

    if (name.length === 2) {
      return `${name[0][0]}${name[1][0]}`;
    }

    return `${name[0][0]}${name[1][0] || name[0][1]}`;
  }
}
