import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CreateAgentData } from '../../types/agents.interface';
import { ResponseRoot } from '../../types/root.interface';
import {
  NewUser,
  PutUserMe,
  PutUserResponse,
  User,
  UserMe,
  UserMeRequest,
  UserRequest,
} from '../../types/user.interface';
import { EventsService } from '../card-output/events.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private event: EventsService,
  ) {}

  private ME!: UserMe;
  private _agent!: CreateAgentData;

  public get agent(): CreateAgentData {
    return this._agent;
  }
  public set agent(value: CreateAgentData) {
    this._agent = value;
  }

  public get me() {
    return this.ME;
  }

  public set me(value: UserMe) {
    this.ME = value;
  }

  private generateRandomPassword() {
    const randomPassword = Math.random().toString(36).slice(-8);
    const insertUpperCaseInPassword = 'abcdefghijklmnopqrstuvwxyz'[
      Math.floor(Math.random() * 26)
    ].toUpperCase();
    const insertSpecialPassword = '#?!@$%^*-+&'[Math.floor(Math.random() * 10)];
    return insertUpperCaseInPassword + randomPassword + insertSpecialPassword;
  }

  public getRandomPassword() {
    return this.generateRandomPassword();
  }

  public createUserWithRandomPassword(user: NewUser) {
    user.password = this.generateRandomPassword();
    return this.createUser(user);
  }

  public getDataOfUser(): Observable<UserMe> {
    return this.http.get<UserMeRequest>(environment.users.USERS_URL_ME).pipe(
      catchError((err) => {
        return EMPTY;
      }),
      map((user) => user.data),
    );
  }

  public getUserById(id: number): Observable<PutUserResponse> {
    return this.http
      .get<PutUserResponse>(`${environment.users.USERS_URL}/${id}`)
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
      );
  }

  public updateUser(user: User) {
    return this.http
      .put<PutUserResponse>(`${environment.users.USERS_URL}/${user.id}`, user)
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
      );
  }

  public updateUserMe(user: PutUserMe) {
    return this.http
      .put<PutUserResponse>(`${environment.users.USERS_URL_ME}`, user)
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
      );
  }

  public createUser(user: NewUser): Observable<User> {
    return this.http
      .post<PutUserResponse>(environment.users.USERS_URL, user)
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
        map((user) => user.data),
      );
  }

  public getUsers(): Observable<User[]> {
    return this.http
      .get<UserRequest>(`${environment.users.USERS_URL}?page=1&per_page=10`)
      .pipe(
        catchError((err) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
        map((users) => users.data),
      );
  }

  public deleteUser(id: number) {
    return this.http.delete<User>(`${environment.users.USERS_URL}/${id}`).pipe(
      catchError((err) => {
        this.event.emitEventForStoreSuccess({
          visible: true,
          message: err.error.message,
          event: 'error',
        });
        return EMPTY;
      }),
    );
  }

  public addImageProfileForUser(image: File): Observable<ResponseRoot> {
    const formData = new FormData();
    formData.append('image', image);

    const header = new HttpHeaders({
      enctype: 'multipart/form-data',
      mimeType: 'multipart/form-data',
    });

    return this.http
      .post<ResponseRoot>(
        environment.users.USER_URL_PROFILE_IMAGE,
        {
          formData,
        },
        { reportProgress: true, headers: header },
      )
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.event.emitEventForStoreSuccess({
            visible: true,
            message: err.error.message,
            event: 'error',
          });
          return EMPTY;
        }),
      );
  }
}
