import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EventToast } from '../../types/event-toast.interface';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private storeSuccess = new BehaviorSubject<EventToast>({
    visible: false,
    message: '',
    event: 'success',
  });

  public emitEventForStoreSuccess({ visible, message, event }: EventToast) {
    this.storeSuccess.next({ visible, message, event });
    setTimeout(() => {
      this.storeSuccess.next({
        visible: false,
        message: '',
        event: 'success',
      });
    }, 3500);
  }

  public getStatisticsOfToast() {
    return this.storeSuccess;
  }
}
